import React, {useState} from "react"
import IMAGE from '../../images/about-1.jpg'
import Icon from "../icon";
import { connect } from "react-redux"
import Formulario from '../Contact.jsx'

import {getDescription,descriptionIsNull, getCover} from '../../helpers/helper.rendering'
import {getTags,getBluePrints, getFakeAddres, getLocation,getTour, getLastVideo, getOperations, getType} from '../../helpers/helper.properties'
import Breadcrumbs from "../breadcrumbs";

import Map from '../map'

import SimpleReactLightbox, { SRLWrapper, useLightbox }from 'simple-react-lightbox'
import { graphql, useStaticQuery } from 'gatsby';

import Loading from '../../components/Loading'


const options = {
    settings: {
        overlayColor: "rgba(0, 0, 0, 0.9)",
        autoplaySpeed: 1500,
        transitionSpeed: 900,
    },
    buttons: {
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        iconColor: "rgba(255,255,255, 1)",
    },
    caption: {
        captionColor: "#000",
        captionFontFamily: "Raleway, sans-serif",
        captionFontWeight: "300",
        captionTextTransform: "uppercase",
    }
};


const About = ({property,loading,dispatch}) => {
    const [charMax,setCharMax] = useState(1000)
    const { openLightbox, closeLightbox } = useLightbox()
    const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            name
            global_email
            global_phone
            branch_office{
              contact {
                phone
                mail
                whatsapp
              }
            }
        }
  }`)
    return !loading ? (
        <section class="ficha-about prop">
            <div class="container-fluid">
                <div class="row no-gutters pb-5">
                    <div className="col-12">
                        {
                            property ?
                             <Breadcrumbs
                                props={[
                                    { name: realEstate.name, route: "/", location: "" },
                                    { name: getOperations(property)[0] , route:   "/" + getOperations(property)[0].toLowerCase(), location: ""},
                                    { name: getType(property) , route:   "/" + getOperations(property)[0].toLowerCase() + "/" + getType(property).toLowerCase(), location: ""},
                                    { name: getFakeAddres(property) , route:   "", location: ""},
                                ]}
                                />
                                :''
                        }
                    </div>
                    <div class="col-lg-7">
                        <h4 class="mb-5">Descripción de la propiedad.</h4>
                        <p class="description-ficha paragraph-normal">{property && 
                            getDescription(property,charMax).map((description,index) => (
                               <>
                                {description}
                                <br />
                               </>
                            ))
                        }
                        {charMax === 1000 && !descriptionIsNull(property) && <span class="d-block text-right text-expand"><a onClick={() => setCharMax(9999)}>Ver más</a></span>}
                        {charMax === 9999 && <span class="d-block text-right text-expand"><a onClick={() => setCharMax(1000)}>Ver menos</a></span>}
                        </p>
                    </div>
                    <div class={"col-lg-4 offset-lg-1 " + (getTags(property).length > 0 ? '' : 'd-none') }>
                        <h4 class="mb-5 mt-5 mt-lg-auto ">Detalles</h4>
                        <div class="row no-gutters amenities">
                            {property && getTags(property).map((tag,index) => (
                                <div class="col-6 mb-3 item">
                                    <i class="icon-checked"></i>
                                    <p>{tag.name}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div class={"blueprints py-5 " + (getBluePrints(property).length > 0 ? '' : ' d-none')} id="planos">
                    <h4 class="mb-5">Planos</h4>
                    <div class="row">
                    <SimpleReactLightbox>
                        <SRLWrapper options={options}>
                            {getBluePrints(property).map((blueprint,index) => (
                                <div class="col-md-6">
                                    <div class="plano mb-3">
                                        <img class="d-lg-block" style={{position:"relative",zIndex:"0",cursor:"pointer"}} src={blueprint.original} alt={realEstate?.name} />
                                        {/* <a class="d-none d-lg-block icon-fullscreen" onClick={() => openLightbox(2)} > </a> */}
                                        <span className="legend-fullscreen">Haz click en la imagen para expandir</span>
                                    </div>
                                </div>
                            ))}
                        </SRLWrapper>
                    </SimpleReactLightbox>
                    </div>
                </div>
                <div id="video" class={"row global-view " + (getLastVideo(property) === undefined ? 'd-none' : '')}>
                    <div class="col-12">
                        <h4 class="mb-5">Video</h4>
                    </div>
                    <div class="col-12">
                        <div class="vista">
                            <iframe allowfullscreen src={getLastVideo(property) ? getLastVideo(property).player_url : ''} frameborder="0"></iframe>
                        </div>
                    </div>
                </div>
                <div id="tour360" class={"row global-view " + (getTour(property) === undefined ? 'd-none' : '')}>
                    <div class="col-12">
                        <h4 class="mb-5">Tour 360°</h4>
                    </div>
                    <div class="col-12">
                        <div class="vista">
                            <iframe allowfullscreen src={getTour(property) ? getTour(property).player_url : ''} frameborder="0"></iframe>
                        </div>
                    </div>
                </div>
                <div class="row ubicacion-main">
                    <div class="col-lg-2">
                        <p class="section-name">Ubicación</p>
                    </div>
                    <div class="col-lg-10">
                        <h2 class="prop-title">{getFakeAddres(property)} <br /> {getLocation(property)}</h2>
                    </div>
                    <div class="col-12">
                        <Map coordenadas={{ geo_lat: property.geo_lat, geo_long: property.geo_long }} />
                    </div>
                </div>
                <div class="row contacto-main pb-5">
                    <div class="col-lg-5 col-12">
                        <h2>Consultanos <br class="d-none d-lg-block" /> por esta  propiedad </h2>
                        <div class="content-contact">
                            {/* <a class="mailto" href={"mailto:" + realEstate.email}><h5>{realEstate.email}</h5></a> */}
                            <div class="row mb-lg-3">
                                <div class="col-lg-8">
                                    {/* <a href="https://web.whatsapp.com/send?text=%C2%A1Hola!&phone=+5491127845344"><h5>Whatsapp 113-913-0000</h5></a> */}
                                    {/* <a target="_blank" href={"https://api.whatsapp.com/send?phone=+549" + (realEstate.branch_office[0].contact.whatsapp.replaceAll("-",""))}>Whatsapp {realEstate.branch_office[0].contact.whatsapp}</a> */}
                                    {/* <a><h5>TEL {realEstate.phone[0]} / {realEstate.phone[1]}</h5></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-12 mt-4 mt-lg-0">
                        <div class="row justify-content-end">
                            <div class="col-lg-11">
                                <Formulario property={property} from={'property'} />
                                <div id="alert-message"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    ):
    <>
      <Loading absolute={true}/>
    </>
}

export default connect(state => ({
    property: state.properties.property,
    loading: state.properties.loading,
}),null)(About);