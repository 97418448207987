  //Funcion para listar/eliminar favoritos en localStorage
  export const addFavorites = (id,type,inmobiliaria) =>{
    if(localStorage.getItem(type + "_favorito_" + inmobiliaria )){
        const storage = localStorage.getItem(type + "_favorito_" + inmobiliaria )
          var array_storage = storage.split("-")
          array_storage = array_storage.filter(element => element != "")
          if(array_storage.find(element => element === id.toString())){
              localStorage.setItem(type + "_favorito_" + inmobiliaria ,"")
              var array_new = (array_storage.filter(element => element != id.toString()))
              for (let index = 0; index < array_new.length; index++) {
                  localStorage.setItem(type + "_favorito_" + inmobiliaria , (localStorage.getItem(type + "_favorito_" + inmobiliaria )  + array_new[index] + "-"))
              }
              return null
          }
          else{
              localStorage.setItem(type + "_favorito_" + inmobiliaria , (localStorage.getItem(type + "_favorito_" + inmobiliaria ) +  id + "-"))
          }
    }
    else{
        localStorage.setItem(type + "_favorito_" + inmobiliaria , id + "-")
    }
      return null
  }
  
  //Función para checkear un favorito
  export const checkFavorite = (id,type,inmobiliaria) => {
    var storage = localStorage.getItem(type + "_favorito_" + inmobiliaria ).split("-") // Recupero los ids
    console.log(storage)
    storage = storage.filter(element => element !== "") // Elimino si hay algun espacio vacio
    if(storage.find(element => element.toString() === id.toString())){
      return true;
    }
    return false
    return false
  }
  
  //Funcion para traer todos los favoritos
  export const getfavorites = (type,inmobiliaria) => {
    var storage = localStorage.getItem(type + "_favorito_" + inmobiliaria ) // Recupero los ids
    if(!storage){localStorage.setItem(type + "_favorito_" + inmobiliaria ,"")} // Si no hay nada en el localStorage lo inicializo
    storage = localStorage.getItem(type + "_favorito_" + inmobiliaria ).split("-") // Recupero los ids
    storage = storage.filter(element => element !== "") // Elimino si hay algun espacio vacio
    return storage //Retorno array de ids
    return []
  }