import { Link } from 'gatsby';

import OwlCarousel from 'react-owl-carousel3';
import React, {useState, useRef} from 'react'

import { connect } from 'react-redux'

import { getFavoritesAction,clearFavoritesAction } from '../redux/propertiesDucks'

//Helpers
import { getCover,getPreviewSlider } from '../helpers/helper.rendering';
import { addFavorites, getfavorites, checkFavorite } from '../helpers/helper.favorites';
import { 
    getOperations,
    getType,
    getEnvironment,
    getSurface,
    getLocation,
    getFakeAddres,
    getPrices,
    makeLink,
    } from '../helpers/helper.properties';

import toast, { Toaster } from 'react-hot-toast';
import { graphql, useStaticQuery } from 'gatsby';

const Card = (props) => {

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
          }
    }`)

    const { property } = props
    const { settings } = props
    const { dispatch } = props

    const [listFavorites,setListFavorites] = useState(getfavorites("prop",settings.short_name))
    const slider = useRef()

    const toastCheck = (id,fake_address) => {
        if(!checkFavorite(id,"prop",settings.short_name))
            return toast.success(fake_address + " agregada a tus favoritos") 
        else
            return toast.success(fake_address + " eliminada de tus favoritos")
    }

    const checkActive = (id) => {
        setListFavorites(getfavorites("prop",settings.short_name))
        dispatch(clearFavoritesAction())
        for (const iterator of getfavorites("prop",settings.short_name)) {
            //Por cada uno de los favoritos, traigo su respectiva data de la api
            dispatch(getFavoritesAction(iterator))
        }
    } 

    return property && (
        <div class="card prop-card">
            <div class="card-image">
                {props.basic ? 
                    <Link to={makeLink(property)} >
                        <img src={getCover(property.photos)?.original } className='notScale' alt={realEstate?.name} />
                    </Link>
                    :
                    <>
                        <OwlCarousel ref={slider}
                            items={1}
                            margin={0}
                            loop={true}
                            className="overflow-hidden card-carousel">
                                {(getPreviewSlider(property.photos)).map((photo, index) => (
                                    <Link to={makeLink(property)} >
                                        {<img src={index === 0 ? photo?.original : photo?.image} className='notScale' alt={realEstate?.name} />}
                                    </Link>   
                                ))}
                        </OwlCarousel>
                        <div className="arrows">
                            <div className="prev cursor-pointer" onClick={() => slider.current.prev()}>{"<"}</div>
                            <div className="next cursor-pointer" onClick={() => slider.current.next()}>{">"}</div>
                        </div>
                    </>
                }
                    {
                        property && 
                            <div  class="badges">
                                {getOperations(property).map((operation, index) => (
                                    <div key={index} className={"badge " + operation.toLowerCase()}>{operation}</div>
                                ))}
                            </div>
                    }
                <span onClick={() => toastCheck(property.id, property.fake_address) + addFavorites(property.id,"prop",settings.short_name) + setListFavorites(getfavorites("prop",settings.short_name)) + checkActive(property.id)} className={"d-lg-inline-flex icon-solid-like btn fav " +  (listFavorites.find(element => element.toString() === property.id.toString()) ? 'active' : '')}  style={{zIndex:"9999"}}>
                </span>
            </div>
            <div class="card-body">
                <div class="content-extra row d-flex justify-content-between align-items-center">
                    <div class="col-12 col-lg-6 mb-3 extra-data">
                        <span>{getType(property)} ·  {getEnvironment(property)} ambiente{getEnvironment(property) > 1 ? 's' : ''} · {getSurface(property)}</span>
                    </div>
                    <div class="col-12 col-lg-6 mb-2 text-lg-end location">
                        <span>
                            {getLocation(property)}
                        </span>
                    </div>
                </div>
                <div class="content-extra">
                    <h3 class="card-title"><a href="#">{getFakeAddres(property)}</a></h3>  
                    {getPrices(property).map((price, index) => (
                        <p class={"prop-price " + price.type }>{price.currency} {Intl.NumberFormat("de-DE").format(price.price)}</p>
                    ))} 
                </div>
            </div>
        </div>
    )
}

export default connect(state => ({
    settings: state.settings,
}),null)(Card);