import React from "react";
import Card from '../property-card'
import OwlCarousel from 'react-owl-carousel3';


const Expand = () => {
    return (
        <div className=""></div>
        // <section id="featured-props" class="py-lg-5">
            /* <div class="container-fluid pt-5">
                <h2 class="section-title py-lg-5">Propiedades similares.</h2>
            </div> */


            // <div class="props d-flex align-items-center justify-content-center my-5">
                /* <OwlCarousel
                    loop={true}
                    smartSpeed={1000}
                    dots={false}
                    nav={false}
                    center={true}
                    items={2}
                    margin={30}
                    stagePadding={(window.innerWidth*0.1)}
                >
                    {[1,2,3,4,5,6,7].map((index) => (
                        <div className="d-flex align-items-center justify-content-center">
                            <Card />
                        </div>
                    ))}         
                </OwlCarousel> */
        //     </div>
        // </section>
    )
}

export default Expand;